(function($){
	$.fn.visible = function(partial){
		var $t            = $(this),
			$w            = $(window),
			viewTop       = $w.scrollTop(),
			viewBottom    = viewTop + $w.height(),
			_top          = $t.offset().top + 100,
			_bottom       = _top + $t.height(),
			compareTop    = partial === true ? _bottom : _top,
			compareBottom = partial === true ? _top : _bottom;

		return ((compareBottom <= viewBottom) && (compareTop >= viewTop));

	};

})(jQuery);


var allMods = $('.js-animation');

$(window).on('load', function(){
	$(window).on('scroll touchstart', function(e){
		allMods.each(function(i, el){
			var el = $(el);
			if(el.visible(true)){
				el.addClass('come-in');
			}
		});
	}).trigger('scroll');	
});