$(document).ready(function() {
    var selectBtn = $('.js-select-btn');
    var selectBtnText = $('.js-select-btn-text');
    var filterBtn = $('.js-product-filter-btn');
    var filter = $('.js-product-filter');
    var dropdownLink = $('.js-dropdown-link');
    var dropdown = $('.js-dropdown');
    var menuBtn = $('.js-btn-menu');
    var menu = $('.js-menu');
    var body = $('body');

    // Main navigation responsive
    if(menuBtn.length) {
        menuBtn.on('click', function(e){
            e.preventDefault();
            body.toggleClass('active-menu');
        });

        //escape key press
        $(document).on('keyup', function(e) {
            if ( e.keyCode == 27 ) {             
                //close dropdown                
                body.removeClass('active-menu');
            }
        });
        
        $(document).on('click touchend', function(event) {                         
            //close dropdown                        
            if (  !$(event.target).closest(menuBtn).length && !$(event.target).closest(menu).length ) {                
                body.removeClass('active-menu');
            }
        });
    }

    // Dropdown 
    if(dropdownLink.length) {
        dropdownLink.on('click', function(e){
            e.preventDefault();
            var link = $(this);
            var dataDropdown = link.data('dropdown');

            dropdownLink.removeClass('active');
            dropdown.removeClass('active');

            link.toggleClass('active');
            $('.js-dropdown[data-dropdown="'+ dataDropdown +'"]').toggleClass('active');
        }); 

        //escape key press
        $(document).on('keyup', function(e) {
            if ( e.keyCode == 27 ) {             
                //close dropdown                
                dropdownLink.removeClass('active');
                dropdown.removeClass('active');
            }
        });
        
        $(document).on('click touchend', function(event) {                         
            //close dropdown                        
            if (  !$(event.target).closest(dropdownLink).length && !$(event.target).closest(dropdown).length ) {                
                dropdownLink.removeClass('active');
                dropdown.removeClass('active');
            }
        });
    }

    // Select
    selectBtn.on('click', function(e){
        e.preventDefault();

        var btn = $(this);

        btn.parents('.js-select').toggleClass('active');
    });

    selectBtnText.on('click', function(e){
        e.preventDefault();

        var btn = $(this);
        var text = btn.data('text');
        var parent = btn.parents('.js-select');

        $('.js-select-btn-text').removeClass('selected');
        btn.addClass('selected');
        parent.find('.js-select-btn span').html(text);
        parent.removeClass('active');
    });

    // Filter 
    filterBtn.on('click', function(e){
        e.preventDefault();
        
        $(this).toggleClass('active');
        filter.toggleClass('active');
    });

    // Init Select 2
    var select2 = $('.js-select2');
    if(select2.length) {
        select2.each( function(){
            var select = $(this);
            var dataTheme = select.data('theme');

            select.select2({
                theme: dataTheme
            });
        });
    }


    // Counter
    var spinner = $('.js-counter');
    if(spinner.length) {
        spinner.each(function() {
          var box = $(this),
            input = box.find('.js-counter-input')
            btnPlus = box.find('.js-counter-plus'),
            btnMinus = box.find('.js-counter-munis'),
            min = input.attr('min'),
            max = input.attr('max');
    
            btnPlus.on('click', function() {    
                var oldValue = parseFloat(input.val());
                if (oldValue >= max) {
                    var newVal = oldValue;
                } 
                else {
                    var newVal = oldValue + 1;
                }
                input.val(newVal);
                input.trigger("change");
            });
    
            btnMinus.on('click', function() {
                var oldValue = parseFloat(input.val());
                if (oldValue <= min) {
                    var newVal = oldValue;
                } 
                else {
                    var newVal = oldValue - 1;
                }
                input.val(newVal);
                input.trigger("change");
            });
    
        });
    }

    // Tabs 
    var tabs = $('.js-tabs');
    if(tabs.length) {
        tabs.each(function() {
            var tabBtn = $(this).find('.js-tabs-btn');

            tabBtn.on('click', function(e) {
                e.preventDefault();

                var btn = $(this);

                $('.js-tabs-btn ,.js-tabs-content').removeClass('active');

                btn.addClass('active');
                $('.js-tabs-content[data-tab="'+ btn.data('tab') +'"]').addClass('active');
            }); 
        });
    }

    // Slick slider
    var sliderGallery = $('.js-slider-gallery');
    var sliderItemFive = $('.js-slider-item-5');
    var sliderItemOne = $('.js-slider-item-one');
    var sliderReview = $('.js-slider-review');

    if(sliderGallery.length) {
        sliderGallery.each(function(){
            var slider = $(this);
            var wrapper = slider.parents('.js-slider-gallery-wrapeper');
            var prevArrow = wrapper.find('.js-prev-arrow');
            var nextArrow = wrapper.find('.js-next-arrow');

            slider.slick({
                infinite: false,
                slidesToShow: 5,
                slidesToScroll: 1,
                dots: false,
                arrows: true,
                prevArrow: prevArrow,
                nextArrow: nextArrow,
                responsive: [
                    {
                      breakpoint: 1220,
                      settings: {
                        slidesToShow: 4
                      }
                    },
                    {
                        breakpoint: 580,
                        settings: {
                          slidesToShow: 3
                        }
                    }
                ]
            }); 
        }); 
    }

    if(sliderItemFive.length) {
        sliderItemFive.each(function(){
            var slider = $(this);
            var wrapper = slider.parents('.js-slider-item-5-wrapeper');
            var prevArrow = wrapper.find('.js-prev-arrow');
            var nextArrow = wrapper.find('.js-next-arrow');

            slider.slick({
                infinite: false,
                slidesToShow: 5,
                slidesToScroll: 1,
                dots: false,
                arrows: true,
                prevArrow: prevArrow,
                nextArrow: nextArrow,
                responsive: [
                    {
                      breakpoint: 1220,
                      settings: {
                        slidesToShow: 4
                      }
                    },
                    {
                        breakpoint: 940,
                        settings: {
                          slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                          slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 540,
                        settings: {
                          slidesToShow: 1
                        }
                    }
                ]
            }); 
        }); 
    }

    if(sliderItemOne.length) {
        sliderItemOne.each(function(){
            var slider = $(this);
            var sliderWrap = slider.parent('.js-slider-item-one-wrap');
            var nextArrow = sliderWrap.find('.js-next-arrow');
            var prevArrow = sliderWrap.find('.js-prev-arrow');
            var currentSlideHtml = sliderWrap.find('.js-current-slide');
            var allSlidesHtml = sliderWrap.find('.js-all-slide');

            slider.on('init', function(event, slick) {
                var allSlides = $('.slick-slide').length;

                allSlidesHtml.html(allSlides);
                currentSlideHtml.html(1);
            });

            slider.slick({
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: true,
                prevArrow: prevArrow,
                nextArrow: nextArrow,
            });

            slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                currentSlideHtml.html(nextSlide + 1);
            });
        });
    }

    if(sliderReview.length) {
        sliderReview.each(function(){
            var slider = $(this);
            var sliderWrap = slider.parent('.js-slider-review-wrap');
            var nextArrow = sliderWrap.find('.js-next-arrow');
            var prevArrow = sliderWrap.find('.js-prev-arrow');

            slider.slick({
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: true,
                prevArrow: prevArrow,
                nextArrow: nextArrow,
                responsive: [
                    {
                      breakpoint: 540,
                      settings: {
                        arrows: false
                      }
                    },
                ]
            });
        });
    }


    // Lightgallery
    var lightgalleryBtn = $('.js-btn-lightgallery');

    lightgalleryBtn.on('click', function(e){
        e.preventDefault();

        var galleryID = $(this).data('gallery');
        var gallery = $('.js-lightgallery[data-gallery='+ galleryID +']');

        gallery.lightGallery({
            download: false,
        });

        gallery.find('a:first').trigger('click');
    });

    if( $('.js-product-gallery').length) {
        $('.js-product-gallery').lightGallery({
            selector: '.js-product-gallery-item'
        });
    }

    // Form dropdown 
    var formDropdownBtn = $('.js-btn-form-dropdown');
    var formDropdownBtnClose = $('.js-btn-close-form-dropdown');
    var formDropdown = $('.js-form-dropdown');

    if(formDropdown.length){
        formDropdownBtn.on('change', function(){
            var input = $(this);
            var inputData = input.data('dropdown');

            if(input.is(':checked')) {
                formDropdown.removeClass('active');
                $('.js-form-dropdown[data-dropdown="'+ inputData +'"]').addClass('active');
            }
            else {
                formDropdown.removeClass('active');
            }
        });

        formDropdownBtnClose.on('change', function(){
            var input = $(this);
            var inputData = input.data('dropdown');

            if(input.is(':checked')) {
                $('.js-form-dropdown[data-dropdown="'+ inputData +'"]').removeClass('active');
            }
        });
    }
});